$spacer: 1rem;
$blue: #007db8;
$white: #fff;

@import "./Menu/menu.scss";

.dropdown-item a {
  text-decoration: none;
}

.btn-tertiary:focus::before {
  border: none;
}

.icon-button {
  display: inline-flex;
  line-height: 3rem;
  background: transparent;
  vertical-align: middle;
  text-align: center;
  border: none;
  height: 3rem;
  width: 3rem;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.1);
  }
}

.site-header-mobile,
.site-header-desktop {
  position: relative;
  z-index: 1000;
}

.site-header-mobile {
  .nav-link {
    text-decoration: none;
    cursor: pointer;
  }
  img {
    height: 1.5rem;
  }
}

.site-header-desktop {
  height: 3.75rem;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background: $white;
  .nav-link {
    text-decoration: none;
  }
  .logo {
    display: block;
    box-sizing: content-box;
    position: relative;
    top: -0.05em;
    height: 1.75rem;
    padding: 1rem 0;
    margin-right: 1rem;
    img {
      display: block;
      height: 100%;
    }
  }
  .main-nav {
    flex-wrap: nowrap;
    .nav-link {
      padding: 1.125rem 1rem;
      text-decoration: none;
      font-weight: 500;
      letter-spacing: 0.01em;
    }
    .nav-link:hover,
    .nav-link:focus,
    .nav-link.active,
    .expanded .nav-link {
      background: $component-active-bg;
      color: $component-active-color;
    }
    .menu {
      position: static;
      .menu-content {
        border-top: solid 2px $component-active-bg;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        padding: 1rem;
      }
    }
  }
  .search-input {
    border-radius: $rounded-pill;
  }
}
