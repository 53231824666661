$darkCyan: #00262b;

.learner-information {
  color: black;
  .nav-link.active {
    border-color: #fff;
    color: $darkCyan;
    border-bottom-color: $darkCyan;
    border-width: medium;
  }
}

.container-fluid {
  // Reverting the container width to 1440px to keep the support tools styling consistent
  // See https://github.com/openedx/paragon/pull/533 for paragon breaking change
  max-width: 1440px;
}

.custom-table {
  font-size: smaller;
  width: 100%;
  th {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
  }
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid silver;
  }
}

.sso-table {
  width: auto;
  font-size: smaller;
  th {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid gray;
  }
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid gray;
  }
}

.idv-table {
  @extend .custom-table;

  tr:last-child td {
    border-bottom: none;
  }
}

.badge-status {
  border-radius: 3px;
}

.account-info {
  font-size: smaller;
  width: auto;
  border: none;
  border-radius: 0;

  border-right: 1px solid lightgray;

  table th {
    margin: 0.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid lightgray;
    width: 30%;
  }
  table td {
    margin: 0.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid lightgray;
    word-break: break-word;
    width: 70%;
  }
}

.account-actions {
  border: 1px solid lightgray;
  border-radius: 0.375rem;
}

.fbe-table {
  @extend .sso-table;

  tr:last-child th,
  tr:last-child td {
    border-bottom: none;
  }
}
.card-title {
  color: #00262B !important
}

.certificate-info-table {
  @extend .sso-table;
  width: 100%;
}

.course-summary-table {
  @extend .certificate-info-table;
}

.course-runs-table {
  font-size: smaller;
  width: 100%;
  th {
    margin: 0;
    padding: 0.5rem;
  }
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 0px;
    border-top: 1px solid silver;
  }
}

.custom-expander-table {
  font-size: smaller;
  margin-left: 10%;
  th {
    margin: 0;
    padding: 0.5rem;
    background-color: lightgray;
    border-bottom: 0px;
  }
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 0px;
  }
}

.text-center {
  margin: auto;
}

.support-tools-tab {
  border-bottom: none;

  .nav-link {
    border: 1px solid lightgray;
    color: black;
  }

  .nav-link.active {
    background-color: $darkCyan;
    color: white;
    font-weight: normal;
  }
}

.background-light-gray {
  background: lightgrey;
}

.mb-60 {
  margin-bottom: 60px;
}

.no-record-text {
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.inspector-name-row{
  border-bottom: 1px solid black;
}
.modal-title{
  font-size: 1.9rem !important;
  }