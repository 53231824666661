button.toggle-password {
  max-width: 150px;
}

button.neg-margin-top {
  margin-top: -7px;
}

a.word_break {
  word-break: break-word;
}

div.no-public-link {
  max-width: 464px;
}
