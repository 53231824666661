.sub-component {
  margin-left: 13rem;
}
.pgn-doc__icons-table__preview-footer {
  display: flex;

  .pgn__icon {
    align-self: center;
    margin-left: 8px;
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    cursor: pointer;

    .pgn__icon {
      opacity: 1;
    }
  }
}

.customer-name {
  font-size: 15px;
  color: black !important;
  font-weight: bold;
}

.icon-button {
  opacity: 1;
  flex-shrink: 0;
  pointer-events: none;
}